import tippy from "tippy.js";
import { isNil } from "lodash-es";

export const setupTippyHint = (elem) => {
  const allowHTML = elem.dataset.hintHtml !== undefined;
  const content = allowHTML ? elem.dataset.hintHtml : elem.dataset.hint;

  tippy(elem, {
    content,
    allowHTML,

    // otherwise 'interactive' hints can end up small since they will be appended to parent
    appendTo: document.body,

    // allows clicking links
    interactive: !isNil(elem.dataset.hintInteractive) && elem.dataset.hintInteractive !== "false",
    trigger: "mouseenter focus click",
    theme: "recipal",
  });

  /* When used in sortable tables, clicking shouldn't sort */
  elem.addEventListener("click", (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
  });
};
