/* This file contains symbols that are exposed on window/globalThis for access by RJS views. */

import "chartkick/chart.js";

import { Confirm } from "./confirm.js";
import { Flash } from "./flash.js";
import { Highlighter } from "./highlighter.js";
import { Modal } from "./modal.js";
import Rails from "@rails/ujs";
import { RemoteModal } from "./remote_modal.js";
import { Spinner } from "./spinner.js";
import { StashedFile } from "./stashed_file.js";
import { StashedFileUI } from "./stashed_file_ui.js";
import { Undo } from "./undo.js";
import { VideoModal } from "./video_modal.js";
import { ifView, onViewLoad } from "./on_view_load.js";

import {
  findPartial,
  findPartials,
  replacePartial,
  selectorForPartial,
} from "./replace_partial.js";

import { warnUnsavedTrigger } from "./warn_unsaved_trigger.js";

Object.assign(globalThis, {
  Confirm,
  Flash,
  Highlighter,
  Modal,
  Rails,
  RemoteModal,
  Spinner,
  StashedFile,
  StashedFileUI,
  Undo,
  VideoModal,

  ifView,
  onViewLoad,

  findPartial,
  findPartials,
  replacePartial,
  selectorForPartial,

  warnUnsavedTrigger,
});
