/*
 * This minor headache is what allows us to use the minimal set of FA icons without bringing in
 * huge files.  We explicitly import the icons we need here, and `dom.watch` replaces them on
 * page with their SVG equivalents. */
import { dom, library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowUpRightFromSquare,
  faArrowRight,
  faAsterisk,
  faBars,
  faBug,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClockRotateLeft,
  faCogs,
  faCopy,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faEquals,
  faEuroSign,
  faExclamationTriangle,
  faEye,
  faFilm,
  faGift,
  faInfoCircle,
  faLock,
  faMinus,
  faMinusSquare,
  faNotEqual,
  faPaintRoller,
  faPen,
  faPenToSquare,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faQuoteLeft,
  faRetweet,
  faSearch,
  faSearchPlus,
  faSterlingSign,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUndo,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUsers as faUsersSolid,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowUpRightFromSquare,
  faArrowRight,
  faAsterisk,
  faBars,
  faBug,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClockRotateLeft,
  faCogs,
  faCopy,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faEquals,
  faEuroSign,
  faExclamationTriangle,
  faEye,
  faFilm,
  faGift,
  faInfoCircle,
  faLock,
  faMinus,
  faMinusSquare,
  faNotEqual,
  faPaintRoller,
  faPen,
  faPenToSquare,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faQuoteLeft,
  faRetweet,
  faSearch,
  faSearchPlus,
  faSterlingSign,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUndo,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUsersSolid
);

import {
  faFilters,
  faGears,
  faGrid2,
  faLaptopMobile,
  faList,
  faLock as faLockDuotone,
  faPrint,
  faRectanglesMixed,
  faShop,
  faTruckFast,
  faUser as faUserDuotone,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faFilters,
  faGears,
  faGrid2,
  faLaptopMobile,
  faList,
  faLockDuotone,
  faPrint,
  faRectanglesMixed,
  faShop,
  faTruckFast,
  faUserDuotone,
  faUsers
);

import { faCube, faCubes } from "@fortawesome/sharp-duotone-solid-svg-icons";
library.add(faCube, faCubes);

import {
  faCloud,
  faCalendarDays,
  faEyeSlash,
  faLock as faLockRegular,
  faMinusSquare as faMinusSquareRegular,
  faPeanuts,
  faPlusSquare as faPlusSquareRegular,
  faRectangleList,
  faSparkles,
  faTrashUndo,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faCalendarDays,
  faCloud,
  faEyeSlash,
  faLockRegular,
  faMinusSquareRegular,
  faPeanuts,
  faPlusSquareRegular,
  faRectangleList,
  faSparkles,
  faTrashUndo
);

import { faCommentsQuestionCheck, faSlidersH } from "@fortawesome/pro-light-svg-icons";
library.add(faCommentsQuestionCheck, faSlidersH);

import {
  faArrowAltCircleUp,
  faEye as faEyeRegular,
  faPlayCircle,
} from "@fortawesome/free-regular-svg-icons";

library.add(faArrowAltCircleUp, faEyeRegular, faPlayCircle);

import {
  faTwitter,
  faFacebook,
  faInstagram,
  faCanadianMapleLeaf,
} from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faFacebook, faInstagram, faCanadianMapleLeaf);

dom.watch();
